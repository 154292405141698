<template>
  <QuestionHeader></QuestionHeader>
  <div class="question_container">
    <div class="question_answers">
      <business-questions></business-questions>
      <navigation></navigation>
      <QuestionSteps></QuestionSteps>
    </div>
    <div class="question_info">
      <question-info :description="questionDescript" ></question-info>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import BusinessQuestions from "@/components/questions/BusinessQuestions";
import QuestionInfo from "@/components/QuestionInfo";
import QuestionSteps from "@/components/QuestionSteps";
import QuestionHeader from "@/components/QuestionHeader";

export default {
  name: "Business",
  components: {QuestionHeader, QuestionSteps, BusinessQuestions, Navigation, QuestionInfo},
  setup(){
    const questionDescript = "Vil du have flere kvalificerede leads? Det er praktisk at kende detaljer om din Buyer " +
        "Personas forretning, når du opretter felter til dine formularer. Faktorer som industri og virksomhedsstørrelse " +
        "hjælper dig med at forstå, hvor mange hatte denne person kan have på til dagligt, eller hvor konkurrencedygtigt" +
        " deres marked er."

    return{
      questionDescript
    }
  }
}
</script>

<style scoped>

</style>